import React, { useState } from "react"
import { Link } from "gatsby"
import './navbar.css'

const Navbar = () => {
    // const [scrolled, setScrolled] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    // const toggleMenu = () => {
    //     setIsOpen(!isOpen);
    // };

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.scrollY < 80) {
    //             setScrolled(window.scrollY);
    //         } else {
    //             setScrolled(80);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // });

    return (
        <header>
            <nav>
                <div className="p-4 bg-pink-400 flex justify-between items-center" style={{ minHeight: '5rem', maxHeight: '5rem' }}>
                    <Link
                        to="/"
                        className="text-white text-4xl ffb tracking-wider">
                        BitBacon
                    </Link>

                    <section className="gap-10 hidden md:flex text-white pr-4 ffr text-xl">
                        <Link
                            to="/"
                            activeClassName="underline underline-offset-8"
                            className="hover:underline hover:underline-offset-8">
                            Home
                        </Link>
                        <Link
                            to="/projects"
                            activeClassName="underline underline-offset-8"
                            className="hover:underline hover:underline-offset-8">
                            Projects
                        </Link>
                        <Link
                            to="/about"
                            activeClassName="underline underline-offset-8"
                            className="hover:underline hover:underline-offset-8">
                            About
                        </Link>
                        <Link
                            to="/contact"
                            activeClassName="underline underline-offset-8"
                            className="hover:underline hover:underline-offset-8">
                            Contact
                        </Link>
                    </section>

                    <button className={`burger-button flex flex-col justify-between cursor-pointer md:hidden ${isOpen ? "open" : ""}`}
                        onClick={() => setIsOpen(!isOpen)} aria-label="menu-burger">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <section className={`menu bg-white bg-opacity-90 fixed ffl ${isOpen ? "open" : ""}`}
                            style={{ top: "80px", minHeight: "calc(100dvh - 80px)" }}>
                            <ul className="text-stone-800 text-lg">
                                <li style={{ padding: 0 }}>
                                    <Link
                                        to="/"
                                        activeClassName="underline underline-offset-4"
                                        className=" hover:underline hover:underline-offset-4 w-full">
                                        <div className="w-full p-4 pl-5">Home</div>
                                    </Link>
                                </li>
                                <li style={{ padding: 0 }}>
                                    <Link
                                        to="/projects"
                                        activeClassName="underline underline-offset-4"
                                        className=" hover:underline hover:underline-offset-4 w-full">
                                        <div className="w-full p-4 pl-5">Projects</div>
                                    </Link>
                                </li>
                                <li style={{ padding: 0 }}>
                                    <Link
                                        to="/about"
                                        activeClassName="underline underline-offset-4"
                                        className=" hover:underline hover:underline-offset-4 w-full">
                                        <div className="w-full p-4 pl-5">About</div>
                                    </Link>
                                </li>
                                <li style={{ padding: 0 }}>
                                    <Link
                                        to="/contact"
                                        activeClassName="underline underline-offset-4"
                                        className=" hover:underline hover:underline-offset-4 w-full">
                                        <div className="w-full p-4 pl-5">Contact</div>
                                    </Link>
                                </li>
                            </ul>
                        </section>
                    </button>
                </div>
            </nav>
        </header>
    )
}

export default Navbar
