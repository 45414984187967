import * as React from "react"

const Footer = () => {

  return (
    <footer className="flex justify-center text-xs items-center w-full bg-black tracking-wider" style={{minHeight: '4rem', maxHeight: '4rem'}}>
      <span className="text-stone-400 ffl">BitBacon © {new Date().getFullYear()}</span>
    </footer>
  )
}

export default Footer