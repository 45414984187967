import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types"
import Navbar from "./navbar";
import Footer from './footer';

const Layout = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
    setTimeout(() => {
      setIsVisible2(true);
    }, 400);
  }, []);

  return (
    <main className={`bg-black appear-component ${isVisible ? 'animate-appear' : ''}`} >
      <div className="flex flex-col w-full h-full">
        <Navbar />
        <div className={`h-full w-full appear-component ${isVisible2 ? 'animate-appear' : ''}`}>{children}</div>
        <Footer />
      </div>
    </main>
    // <div className="bg-black">
    //   <div className="flex flex-col w-full h-full">
    //     <Navbar />
    //     <div className="h-full w-full">{children}</div>
    //     <Footer />
    //   </div>
    // </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
