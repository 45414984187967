module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BitBacon Official Site","short_name":"BitBacon","start_url":"/","background_color":"#f8d7b7","theme_color":"#000000","display":"standalone","icon":"./src/static/images/favicon.png","icons":[{"src":"./src/static/images/favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"./src/static/images/favicon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ae71afea2cdc397e7988e7b9f5a4e9c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-P9V924R9JF"],"pluginConfig":{"head":false,"respectDNT":true,"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0,"exclude":[]},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
